<template>
  <div class='treeMenu' :class="{'firstMenu flex-aic':isFlex}">
    <template v-for="item in menuData">
    <el-submenu v-if="item.childPageMenus.length>0&&item.buttonMenus.length==0" :index="item.id" :key="item.id">
      <template slot="title">
        <!-- <div :class="{'menuLevel1':item.parentId=='0'}"> -->
        <!-- <template v-if="item.parentId=='0'"> -->
        <i class="menuImg mr10" :class="item.iconUrl?item.iconUrl:''"></i>
        <!-- </template> -->
        <span slot="title" class="menuName">{{item.menuName}}</span>
        <!-- </div> -->
      </template>
      <tree-menu :menuData="item.childPageMenus" :isFlex="false"></tree-menu>
    </el-submenu>
    <el-menu-item v-else :index="item.menuUrl||item.id" :key="item.id">
      <!-- <div :class="{'menuLevel1':item.parentId=='0'}"> -->
      <!-- <template v-if="item.parentId=='0'"> -->
      <i class="menuImg mr10" :class="item.iconUrl?item.iconUrl:''"></i>
      <!-- </template> -->
      <span slot="title" class="menuName">{{item.menuName}}</span>
      <!-- </div> -->
    </el-menu-item>
    </template>
  </div>
</template>
<script>
var vm;
export default {
  props:{
    menuData: {
      type: Array,
      default: []
    },
    isFlex:{
      type:Boolean,
      default:false,
    }
  },
  components:{
    treeMenu:()=>import('@/components/tree-menu.vue'),
  },
  data(){
    return {
      
    }
  },
  created(){
    vm = this
  },
  mounted(){
    
  },
  methods:{
    //返回图片地址
    setNavIcon (icon){
      return require(`@/assets/images/${icon}.png`)
    },
  },
  
}
</script>
<style lang='scss'>
.treeMenu{
  .el-menu-item{
    height: 50px;
    line-height: 50px;
    margin-bottom: 1px;
    transition: none;
    background: none ;
    font-size: 16px;
    i{
      color: #fff !important;
      font-size: 16px;
    }
    &.is-active,&:hover{
      position: relative;
      background: linear-gradient(to right, #006ae1, #16b7ff);
      color: #fff !important;
      i{
        color: #fff !important;
      }
    }
  }
  .el-submenu{
    .el-submenu__title{
      height: 50px;
      line-height: 50px;
      margin-bottom: 1px;
      background: none ;
      font-size: 16px;
    }
    i{
      color: #fff !important;
      font-size: 16px;
    }
    &.is-active>.el-submenu__title,&:hover>.el-submenu__title{
      background: linear-gradient(to right, #006ae1, #16b7ff);
      color: #fff !important;
      i{
        color: #fff !important;
      }
    }
  }
}
</style>
